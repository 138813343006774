export default [
    {
        path: '/login/dashboard',
        name: 'Member Dashboard',
        component: () => import('../views/frontend/dashboard/index-dashboard.vue'),
        meta: { menu: 'Dashboard', authRequired: true },
    }, {
        path: '/verify/:token',
        name: 'VerifyLink',
        component: () => import('@/views/verify-link.vue'),
    },// In your router configuration file (e.g., router.js)
    {
        path: '/members/:id/:token',
        name: 'PasswordReset',
        component: () => import('@/views/public/password-reset.vue')
    },
    {
        path: '/login/profile-setting',
        name: 'Member Profile',
        component: () => import('../views/frontend/profile-setting.vue'),
        meta: { menu: 'Dashboard', authRequired: true },
    }

];
