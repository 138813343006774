import axiosInstance from './apiService';
import TokenService from './tokenService';
import router from '@/router';

const setup = (store) => {
    // Determine user type based on the request URL
    const getUserType = (url) => {
        if (url.includes('/admin')) {
            return 'admin';
        } else if (url.includes('/login')) {
            return 'member';
        }
        return null; // Default if no match
    };

    // Retrieve access token for the given user type
    const getAccessToken = (type) => TokenService.getLocalAccessToken(type);

    // Handle token refresh logic
    const refreshToken = async (type) => {
        try {
            const response = await axiosInstance.post('/refreshToken', {
                refreshToken: TokenService.getLocalRefreshToken(type),
            });

            if (response.data?.status === 'success') {
                const { accessToken } = response.data.data;
                store.dispatch('auth/refreshToken', { accessToken, type });
                TokenService.updateLocalAccessToken(type, accessToken);
                return accessToken;
            }

            throw new Error('Invalid response for token refresh');
        } catch (error) {
            TokenService.removeUser(type);
            router.push(type === 'admin' ? '/admin' : '/login');
            console.log('Token refresh failed');
        }
    };

    // Axios request interceptor to add Authorization header
    axiosInstance.interceptors.request.use(
        (config) => {
            // Ensure we identify the type accurately
            if (config.url.includes('/refreshToken')) {
                return config; // Skip checking type for refreshToken
            }


            const userType = getUserType(config.url);

            if (!userType) {
                return config; // Proceed without adding Authorization header
            }

            const token = getAccessToken(userType);

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        (error) => Promise.reject(error),
    );

    // Axios response interceptor for handling 401 errors
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalConfig = error.config;

            if (error.response?.status === 401 && !originalConfig._retry) {

                // Mark the request as retried
                originalConfig._retry = true;

                // Accurately check the URL to determine the user type
                const userType = getUserType(originalConfig.url);

                if (!userType) {
                    delete originalConfig.headers['Authorization']; // Ensure no header is sent
                    return axiosInstance(originalConfig); // Retry the request without token       
                }

                try {
                    // Refresh the token and retry the request
                    const newAccessToken = await refreshToken(userType);
                    originalConfig.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosInstance(originalConfig); // Retry the original request
                } catch (refreshError) {
                    // Handle errors during token refresh
                    return Promise.reject(refreshError);
                }
            }

            // Reject the error if it's not a 401 or the retry fails
            return Promise.reject(error);
        },
    );
};

export default setup;

