import api from '../../services/apiService';

let fetchPromise = null; // Promise cache for the ongoing API request

export default {
    namespaced: true,
    state: {
        accountLevels: null, // Cache for account levels
    },
    mutations: {
        setAccountLevels(state, levels) {
            state.accountLevels = levels;
        },
    },
    actions: {
        async fetchAccountLevels({ commit, state }) {
            if (state.accountLevels) {
                // If data is already cached, return it
                return state.accountLevels;
            }

            if (!fetchPromise) {
                // If no ongoing request, make the API call
                fetchPromise = api.get('/admin/accounts/select-data')
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data?.data)) {
                            const levels = response.data.data.map((account) => ({
                                value: account.memid,
                                label: account.name,
                            }));
                            commit('setAccountLevels', levels); // Cache the data in Vuex
                            return levels;
                        }
                        return [];
                    })
                    .catch((error) => {
                        console.error('Error fetching account levels:', error);
                        return [];
                    })
                    .finally(() => {
                        fetchPromise = null; // Reset the promise cache
                    });
            }

            // Return the ongoing fetch promise to all consumers
            return fetchPromise;
        },
    },
};

