// tokenService.js
class TokenService {
    // Retrieve any token by type (admin or member)
    getLocalRefreshToken(type) {
        return this._getUser(type)?.refreshToken;
    }

    getLocalAccessToken(type) {
        return this._getUser(type)?.accessToken;
    }

    updateLocalAccessToken(type, token) {
        const user = this._getUser(type);
        if (user) {
            user.accessToken = token;
            this._setUser(type, user);
        }
    }

    // Get user data from localStorage by type
    getUser(type) {
        return this._getUser(type)?.user;
    }

    setUser(type, user) {
        this._setUser(type, user);
    }

    removeUser(type) {
        localStorage.removeItem(`${type}Menu`);
        localStorage.removeItem(`auth${type}`);
    }

    // Private helper methods
    _getUser(type) {
        return JSON.parse(localStorage.getItem(`auth${type}`));
    }

    _setUser(type, user) {
        localStorage.setItem(`auth${type}`, JSON.stringify(user));
    }
}

export default new TokenService();
