import api from '../services/apiService';

// Utility function to create or update a meta tag
export const setMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.name = name;
        document.head.appendChild(metaTag);
    }
    metaTag.content = content || '';
};

// Utility function to update favicon
export const setFavicon = (url) => {
    let favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
        favicon = document.createElement('link');
        favicon.rel = 'icon';
        document.head.appendChild(favicon);
    }
    favicon.href = url || '/favicon.ico';
};

// Utility function to update document title
export const setDocumentTitle = (title) => {
    document.title = title || 'Default Site Name';
};

export const setGoogleAnalytics = (trackingCode) => {
    if (!trackingCode) {
        // console.warn('No Google Analytics tracking code provided.');
        return;
    }

    // Check if GA script already exists
    const existingScript = document.querySelector(`script[src*="www.googletagmanager.com/gtag/js?id=${trackingCode}"]`);
    if (!existingScript) {
        // Add GA script
        const gaScript = document.createElement('script');
        gaScript.async = true;
        gaScript.textContent = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${trackingCode}');`;
        document.head.appendChild(gaScript);

        // Add GA configuration script securely
        const gaConfigScript = document.createElement('script');
        gaConfigScript.type = 'text/javascript';

        // Use textContent instead of innerHTML to avoid XSS
        gaConfigScript.textContent = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingCode}');
        `;
        document.head.appendChild(gaConfigScript);
    }
};


export const updateSiteMetadata = ({ siteName, faviconUrl, metaDescription, metaKeywords, googleAnalyticsCode }) => {
    // Update site name
    if (siteName) setDocumentTitle(siteName);

    // Update favicon
    if (faviconUrl) setFavicon(faviconUrl);

    // Update meta tags
    if (metaDescription) setMetaTag('description', metaDescription);
    if (metaKeywords) setMetaTag('keywords', metaKeywords);

    if (googleAnalyticsCode) setGoogleAnalytics(googleAnalyticsCode);
};


// Function to fetch settings from API
const fetchSettingsFromAPI = async () => {
    const response = await api.get('/settings');
    if (response.data.status === 'success') {
        return response.data.data;
    } else {
        console.warn('Failed to retrieve site settings.');
        return null;
    }
};


// Function to fetch and update metadata
export const fetchAndUpdateMetadata = async () => {
    const now = Date.now();
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    // Check localStorage for saved settings
    const savedData = localStorage.getItem('siteSettings');
    const parsedData = savedData ? JSON.parse(savedData) : null;

    // If settings exist and are fresh, use them
    if (parsedData && now - parsedData.timestamp < oneDayInMillis) {
        updateSiteMetadata(parsedData.settings);
    } else {
        // Otherwise, fetch new settings from the API
        const settings = await fetchSettingsFromAPI();
        if (settings) {
            // Save the settings in localStorage with the current timestamp
            localStorage.setItem('siteSettings', JSON.stringify({ settings, timestamp: now }));
            updateSiteMetadata(settings);
        }
    }
};

export const getSpecificSetting = (key) => {
    const savedData = localStorage.getItem('siteSettings');
    if (savedData) {
        try {
            const parsedData = JSON.parse(savedData);
            return parsedData.settings[key] || null; // Return specific setting or null if not found
        } catch (error) {
            return null;
        }
    }
    return null;
};
